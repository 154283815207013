import styled from 'styled-components'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { breakpoints } from '../../utils/styles/global-styles'

export const PageContainer = styled.section`
  margin: 2rem auto;
  width: 640px;
  max-width: 90vw;
`

export const PageContent = styled.div`
  * {
    max-width: 100%;
  }
`

export const PageTitle = styled.h1`
  font-size: 38px;
  margin: 0px 0px 0px;
  @media (max-width: ${breakpoints.l}px) {
    padding-top: 40px;
    text-align: center;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 30px;
  }
`
export const SubHeader = styled.h3`
  color: ${(props) => props.color || props.theme.grayDark};
  font-size: 25px;
  line-height: 1.4;
  letter-spacing: 0.13px;
  margin: 40px 0px 10px;
  display: block;
  @media (max-width: ${breakpoints.s}px) {
    font-size: 22px;
    line-height: 30px;
    letter-spacing: 0.12px;
  }
`
export const TosSubHeader = styled(SubHeader)`
  display: inline;
`

export const Highlight = styled.span`
  color: ${(props) => props.theme.golden};
`
export const ALink = styled.a`
  color: #f66951;
  text-decoration: none;
  font-family: 'FilsonProBook';
  font-size: 18px;
  @media (max-width: ${breakpoints.s}px) {
    font-size: 16px;
  }
`
export const Container = styled.div`
  display: flex;
  width: 90%;
  margin: auto;
  padding: 100px 0px 150px;
  div:nth-child(2) {
    padding-right: 100px;
  }
  @media (max-width: ${breakpoints.xl}px) {
    div:nth-child(2) {
      padding-right: 0px;
    }
    flex-wrap: wrap;
    padding: 40px 0px 140px;
  }
`
export const Column = styled.div`
  div {
    width: 400px;
    padding-right: 100px;
    @media (max-width: ${breakpoints.l}px) {
      padding-right: 0px;
      width: 400px;
      max-width: 100%;
      margin: auto;
      display: flex;
      justify-content: center;
      border-bottom: 1.5px solid rgba(68, 68, 68, 0.1);
    }
  }
  @media (max-width: ${breakpoints.l}px) {
    width: 100%;
  }
`
export const Subtitle = styled.p`
  max-width: 490px;
  font-size: 15px;
  line-height: 26px;
  letter-spacing: 0.08px;
  margin: 15px 0px 56px;
  font-family: 'FilsonProBook';
  @media (max-width: ${breakpoints.l}px) {
    text-align: center;
    margin: 20px auto;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 13px;
    line-height: 24px;
    letter-spacing: 0.07px;
  }
`

export const TosSubtitle = styled(Subtitle)`
  max-width: 490px;
  @media (max-width: ${breakpoints.l}px) {
    margin: auto;
  }
`
export const Text = styled.p`
  font-family: ${(props) => (props.bold ? 'FilsonProBookBold' : 'FilsonProBook')};
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.09px;
  margin-top: 12px;
  margin-bottom: 12px;
  @media (max-width: ${breakpoints.s}px) {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.08px;
    > strong {
      font-family: FilsonProBookBold;
    }
  }
`
export const Line = styled.hr`
  opacity: 0.1;
  border-bottom: solid 1.5px rgba(68, 68, 68, 0.1);
`
export const CurrentPage = styled(Text)`
  border-bottom: 1.5px solid #f66951;
  display: inline-block;
  @media (max-width: ${breakpoints.l}px) {
    margin: 18px 20px 18px 20px;
  }
  @media (max-width: ${breakpoints.s}px) {
    margin: 20px 20px 18px 20px;
  }
`
export const PageLink = styled(Link)`
  font-family: 'FilsonProBook';
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.09px;
  text-decoration: none;
  color: #444444;
  display: block;
  margin: 20px 0px;
  @media (max-width: ${breakpoints.s}px) {
    font-size: 16px;
  }
`
export const ListItem = styled.li`
  font-family: 'FilsonProBook';
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.09px;
  list-style-type: none;
  position: relative;
  ::before {
    content: '•';
    position: absolute;
    left: -1em;
    font-size: 20px;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 16px;
  }
`
export const HighlightList = styled.li`
  ::marker {
    color: ${(props) => props.theme.golden};
  }
  font-family: 'FilsonProBook';
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.09px;
  margin-bottom: 24px;
  @media (max-width: ${breakpoints.s}px) {
    font-size: 16px;
    margin-bottom: 16px;
  }
`
export const SubHeaderIndex = styled.li`
  color: ${(props) => props.color || props.theme.grayDark};
  font-size: 25px;
  line-height: 1.4;
  letter-spacing: 0.13px;
  margin: 40px 0px 10px;
  @media (max-width: ${breakpoints.s}px) {
    font-size: 23px;
    letter-spacing: 0.12px;
    line-height: 30px;
  }
`
export const OrderedList = styled.ol`
  list-style-position: inside;
  padding-left: 0;
  li {
    padding-left: 0px;
  }
`

export const PrivacyOrderedList = styled.ol`
  margin: 15px 0px;
`
export const Table = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr 3fr 3fr;
  grid-gap: 10px;
  background-color: ${(props) => props.theme.backgroundLight};
  padding: 10px;
  min-width: 1400px;
  border: 1px solid ${(props) => props.theme.golden};
  > div {
    background-color: rgba(255, 255, 255, 0.8);
    text-align: left;
    padding: 10px;
    font-size: 14px !important;
  }
  div:first-child,
  div:nth-child(2),
  div:nth-child(3),
  div:nth-child(4) {
    font-size: 16px !important;
    color: ${(props) => props.theme.golden};
    text-align: center;
    padding-right: 10px;
  }
  @media (max-width: ${breakpoints.s}px) {
    min-width: 1000px;
    grid-template-columns: 2fr 2fr 2fr 2fr;
  }
`
export const ScrollDiv = styled.div`
  overflow-y: scroll;
  overflow-x: scroll;
  margin: 40px;
  height: 500px;
  @media (max-width: ${breakpoints.s}px) {
    margin: 20px 0px;
    height: 300px;
  }
`
export const OverflowContainer = styled.div`
  overflow: hidden;
`
export const Signature = styled(GatsbyImage)`
  padding-right: 0px !important;
  width: 150px !important;
  div {
    padding-right: 0px;
  }
`
